import React, { useEffect } from 'react';
import Slider from 'react-slick';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

const References = () => {

    const location = useLocation();

    const references = [
        { name: 'Bilişim Vadisi', img: '/assets/images/logo/bv.png' },
        { name: 'Üsküdar Belediyesi', img: '/assets/images/logo/uskudar.png' },
        { name: 'İstanbul Üniversitesi', img: '/assets/images/logo/iu.png' },
        { name: 'Teknofest', img: '/assets/images/logo/teknofest.png' },
        { name: 'Tübitak', img: '/assets/images/logo/tubitak.jpg' },
        { name: 'Üsküdar Atölye', img: '/assets/images/logo/auskudar.jpg' },
        { name: 'İstanbul Belediyesi', img: '/assets/images/logo/ibb.png' },
        { name: 'Biruni Üniversitesi', img: '/assets/images/logo/biruniun.png' },
        { name: 'Biruni Teknopark', img: '/assets/images/logo/birunitp.jpg' },
        { name: 'Piri Reis Üniversitesi', img: '/assets/images/logo/pirireisun.jpg' },
        { name: 'Entertech İstanbul', img: '/assets/images/logo/entertechist.jpg' },
        { name: 'İnavasyonda Kadın', img: '/assets/images/logo/invsynkadin.jpg' },
        { name: 'Turmepa', img: '/assets/images/logo/turmepa.png' },
        { name: 'Üretken Akademi', img: '/assets/images/logo/urt.jpeg' },
        { name: 'İstanbul Sanayi Odası', img: '/assets/images/logo/istsny.png' },    
    ];

    const settings = {
        infinite: true,
        speed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        nextArrow: <FaArrowRight style={{ color: '#3498db', fontSize: '30px', right: '-40px' }}/>, 
        prevArrow: <FaArrowLeft style={{ color: '#3498db', fontSize: '30px', left: '-40px' }}/>,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                const navbarHeight = document.querySelector('nav')?.offsetHeight || 0;
                window.scrollTo({
                    top: element.offsetTop - navbarHeight,
                    behavior: 'smooth',
                });
            }
        }
    }, [location]);

    return (
        <section className="bg-gray-100 py-12" id="references">
            <div className="container text-center w-full max-w-6xl mx-auto">
                <h2 className="text-4xl font-semibold capitalize lg:text-5xl text-blue-950">
                    Referanslarımız
                </h2>

                <div className="mt-16">
                    <Slider {...settings} className='w-full h-full'>
                        {references?.map((reference, i) => (
                            <div key={i} className="w-full max-w-xs px-4">
                                <img
                                    className="object-cover object-center h-56 mx-auto rounded-full border-2 hover:border-blue-300"
                                    src={reference?.img}
                                    alt={reference?.name}
                                />
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    );
};

export default References;
