import React, { useState, useEffect } from 'react';
import { FaBars, FaFacebook, FaTimes } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`fixed w-full z-50 ${scrolling ? 'bg-gray-800' : 'bg-transparent'} transition-all duration-300`}>
      <div className="container px-6 py-4 mx-auto">
        <div className="lg:flex lg:items-center">
          <div className="flex items-center justify-between">
            <a href="/" className='flex items-center gap-3'>
              <img
                className="w-auto h-10 sm:h-14 rounded-full"
                src="/assets/images/logo/lgakriha3.png"
                alt="Akriha Logo"
              />
              <h1 className='text-white text-2xl font-bold'>Akriha</h1>
            </a>

            <div className="flex lg:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="text-white hover:text-gray-300 focus:outline-none focus:text-gray-300"
                aria-label="toggle menu"
              >
                <FaBars className={`w-6 h-6 ${isOpen ? 'hidden' : ''}`} />

                <FaTimes className={`w-6 h-6 ${!isOpen ? 'hidden' : ''}`} />
              </button>
            </div>
          </div>

          <div
            className={`${isOpen ? 'translate-x-0 opacity-100' : 'opacity-0 -translate-x-full'
              } absolute inset-x-0 z-20 flex-1 w-full px-6 py-4 transition-all duration-300 ease-in-out bg-gray-800 lg:mt-0 lg:p-0 lg:top-0 lg:relative lg:bg-transparent lg:w-auto lg:opacity-100 lg:translate-x-0 lg:flex lg:items-center lg:justify-between`}
          >
            <div className="flex flex-col capitalize text-white lg:flex lg:px-16 lg:-mx-4 lg:flex-row lg:items-center">
              <Link to="/#home" className="mt-2 lg:mt-0 lg:mx-4 hover:text-blue-300">Anasayfa</Link>
              <Link to="/#about" className="mt-2 lg:mt-0 lg:mx-4 hover:text-blue-300">Hakkımızda</Link>
              <Link to="/#aboutUs" className="mt-2 lg:mt-0 lg:mx-4 hover:text-blue-300">Biz Kimiz?</Link>
              <Link to="/#references" className="mt-2 lg:mt-0 lg:mx-4 hover:text-blue-300">Referanslar</Link>
              <Link to="/#contact" className="mt-2 lg:mt-0 lg:mx-4 hover:text-blue-300">İletişim</Link>
            </div>

            <div className="flex justify-center text-white mt-6 lg:flex lg:mt-3 lg:-mx-2">
              <a href="https://www.linkedin.com/company/akriha" target='_blank' className="mx-2 transition-colors duration-300 hover:text-blue-400" aria-label="Linkedin">
                <FaLinkedin size={17} />
              </a>
              <a href="https://www.instagram.com/akriha_tech_tr/" className="mx-2 transition-colors duration-300 hover:text-blue-400" aria-label="Instagram" target='_blank'>
                <FaInstagram size={17} />
              </a>
              <a href="https://x.com/akrihatech" target='_blank' className="mx-2 transition-colors duration-300 hover:text-blue-400" aria-label="X">
                <FaXTwitter size={17}/>
              </a>
              <a href="https://www.facebook.com/akrihateknoloji" className="mx-2 transition-colors duration-300 hover:text-blue-400" aria-label="Facebook" target='_blank'>
                <FaFacebook size={17} />
              </a>
            </div>
          </div>

        </div>

      </div>
    </nav>
  );
};

export default Navbar;
